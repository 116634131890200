import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Tooltip from 'components/lib/Tooltip';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Field = (props) => {
  const fieldCSSClass = `field ${
    parsePropsAsBulmaClasses(props)
  }`;

  const tooltip = (
    props.info ? (
      <Tooltip description={props.info}>
        <Button isBare tabIndex={-1}><Icon name="fas fa-question-circle fa-xs" isInfo isSmall /></Button>
      </Tooltip>
    ) : null
  );

  return (
    <div className={fieldCSSClass} style={props.style}>
      {props.label ?
        <label className="label" style={props.labelStyle} htmlFor={props.id}>
          {props.label} {tooltip}
        </label>
        : null
      }
      {props.children}
    </div>
  );
};

Field.propTypes = {
  children: PropTypes.any.isRequired
};

export default Field;
