import React from 'react'

import RedeConfiax from '../../../../../../assets/svgs/RedeConfiax'

import Button from 'components/lib/Button'
import Header from 'components/lib/Header'

// eslint-disable-next-line no-undef
const RedeConfiaxUrl = APP_REDE_CONFIAX_URL
const token = localStorage.getItem('izee-token')

const AccountInformation = ({ confiaxAccount, onCreateAnalysis }) => (
  <>
    <Header
      isTiny
      isUppercase
      subtitle={<hr style={{ margin: '8px 0px 16px 0px' }} />}
    >
      <RedeConfiax />
    </Header>
    {confiaxAccount.has_account ? (
      <>
        {confiaxAccount.can_quote ? (
          <Button
            style={{ marginBottom: 8 }}
            hasThemeColor
            onClick={onCreateAnalysis}
          >
            Solicitar análise
          </Button>
        ) : (
          <span>
            Você não tem permissão para criar análises, solicite o cadastro de seu
            usuário no painel da confiax.
          </span>
        )}
      </>
    ) : (
      <>
        <span>A imobiliária ainda não possui conta na Confiax, </span>
        <a
          href={`${RedeConfiaxUrl}#access_token=${token}&token_type=Bearer&expires_in=`}
        >
          clique aqui para solicitar.
        </a>
      </>
    )}
  </>
)

export default AccountInformation
