import React from 'react'

import { FormFieldText } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'

import { fieldProps } from '../formCreator'

const PropertyMeta = (props) => (
  <>
    <Grid>
      <Grid.Col>
        <FormFieldText {...fieldProps(props, 'registration')} label='Matrícula' />
      </Grid.Col>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'real_state_enrollment')}
          label='Inscrição Imobiliária'
        />
      </Grid.Col>
    </Grid>
    <Grid>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'suggested_rental_value')}
          label='Preço sugerido de locação'
          mask={['number']}
        />
      </Grid.Col>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'suggested_sale_value')}
          label='Preço sugerido de venda'
          mask={['number']}
        />
      </Grid.Col>
    </Grid>
    <Grid>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'iptu')}
          label='IPTU (Aproximado)'
          mask={['number']}
        />
      </Grid.Col>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'condo_fee')}
          label='Condomínio (Mensal aproximado)'
          mask={['number']}
        />
      </Grid.Col>
    </Grid>
    <Grid>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'parking_spaces')}
          label='Número da(s) vaga(s)'
        />
      </Grid.Col>
      <Grid.Col>
        <FormFieldText {...fieldProps(props, 'hobby_boxes')} label='Depósito(s)' />
      </Grid.Col>
    </Grid>
  </>
)

export default PropertyMeta
