import React from 'react'
import jsonp from 'jsonp'

// Components
import { FormFieldCEP, FormFieldText } from 'components/lib/FormFields'
import { fieldProps } from '../formCreator'
import FormGrid from 'components/lib/FormGrid'

// Utils
import { timeDelay } from 'utils/utils'
import { FieldContainer } from './FullAddress/styles'

export default class FullTradingAddress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addressByCEP: {},
      isSearchingForAddress: false,
    }
  }

  renderFieldZipcode(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex
    // const cepValidation = {
    //   isInvalid: this.state.addressByCEP.erro,
    //   message: 'CEP inválido ou inexistente',
    // }

    return (
      <FormGrid field>
        <FormFieldCEP
          {...fieldProps(this.props, 'trading_address_zip_code')}
          // forceInvalid={cepValidation.isInvalid}
          // errorMessage={cepValidation.isInvalid && cepValidation.message}
          isLoading={this.state.isSearchingForAddress}
          info='Se não souber o seu CEP preencha os campos manualmente'
          onChange={this.handleCEP.bind(this)}
          noValidate={this.props.noValidate}
          style={{ width: '100%' }}
          tabIndex={tabIndex}
          isRequired={this.props.isRequired}
          numbersOnly
        />
      </FormGrid>
    )
  }

  renderFieldAdress(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex
    const { defaultValue } = this.props

    return (
      <FormGrid field>
        <FormFieldText
          {...fieldProps(this.props, 'trading_address_address')}
          onChange={(e) => this.props.handleChange('trading_address_address', e)}
          label='Logradouro'
          noValidate={this.props.noValidate}
          valueDefault={
            this.state.addressByCEP.logradouro ||
            defaultValue.trading_address_address
          }
          isRequired={this.props.isRequired}
          isDisabled={
            this.props.formIsWorking ||
            this.state.isSearchingForAddress ||
            this.props.isDisabled
          }
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldNeighborhood(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex
    const { defaultValue } = this.props

    return (
      <FormGrid field>
        <FormFieldText
          {...fieldProps(this.props, 'trading_address_neighborhood')}
          onChange={(e) =>
            this.props.handleChange('trading_address_neighborhood', e)
          }
          label='Bairro'
          noValidate={this.props.noValidate}
          isRequired={this.props.isRequired}
          valueDefault={
            this.state.addressByCEP.bairro ||
            defaultValue.trading_address_neighborhood
          }
          isDisabled={
            this.props.formIsWorking ||
            this.state.isSearchingForAddress ||
            this.props.isDisabled
          }
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldNumber(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex
    const { defaultValue } = this.props

    return (
      <FormGrid field>
        <FormFieldText
          {...fieldProps(this.props, 'trading_address_number')}
          onChange={(e) => this.props.handleChange('trading_address_number', e)}
          valueDefault={defaultValue.trading_address_number}
          isRequired={this.props.isRequired}
          label='Número'
          noValidate={this.props.noValidate}
          mask={['onlyNumbers']}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldAdditionalAdress(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex
    const { defaultValue } = this.props

    return (
      <FormGrid field>
        <FormFieldText
          {...fieldProps(this.props, 'trading_address_additional_address')}
          onChange={(e) =>
            this.props.handleChange('trading_address_additional_address', e)
          }
          valueDefault={defaultValue.trading_address_additional_address}
          label='Complemento (Opcional)'
          noValidate
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldCity(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex
    const { defaultValue } = this.props

    return (
      <FormGrid field>
        <FormFieldText
          {...fieldProps(this.props, 'trading_address_city')}
          onChange={(e) => this.props.handleChange('trading_address_city', e)}
          label='Cidade'
          noValidate={this.props.noValidate}
          isRequired={this.props.isRequired}
          valueDefault={
            this.state.addressByCEP.localidade || defaultValue.trading_address_city
          }
          isDisabled={
            this.props.formIsWorking ||
            this.state.isSearchingForAddress ||
            this.props.isDisabled
          }
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldState(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex
    const { defaultValue } = this.props

    return (
      <FormGrid field>
        <FormFieldText
          {...fieldProps(this.props, 'trading_address_state')}
          onChange={(e) => this.props.handleChange('trading_address_state', e)}
          label='Estado'
          noValidate={this.props.noValidate}
          isRequired={this.props.isRequired}
          valueDefault={
            this.state.addressByCEP.uf || defaultValue.trading_address_state
          }
          isDisabled={
            this.props.formIsWorking ||
            this.state.isSearchingForAddress ||
            this.props.isDisabled
          }
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  render() {
    let tabIndex = ''

    return (
      <React.Fragment>
        <div className='section-identifier mt-4 mb-2'>
          <h1 className='form-section-title'>Endereço Comercial</h1>
        </div>
        <FieldContainer>
          {this.renderFieldZipcode((tabIndex = '1'))}
          {this.renderFieldAdress((tabIndex = '2'))}
          {this.renderFieldNumber((tabIndex = '3'))}
          {this.renderFieldState((tabIndex = '4'))}
          {this.renderFieldCity((tabIndex = '5'))}
          {this.renderFieldNeighborhood((tabIndex = '6'))}
          {this.renderFieldAdditionalAdress((tabIndex = '7'))}
        </FieldContainer>
        <div className='section-identifier mt-4 mb-2'>
          <h1 className='form-section-title'>Endereço do Responsável</h1>
        </div>
      </React.Fragment>
    )
  }

  handleCEP(value, field) {
    this.props.handleChange('trading_address_zip_code', value)
    if (!field.wasFocused || !value || value.length < 9) return
    const searchForCEPDelay = timeDelay(1000)

    this.setState({ isSearchingForAddress: true })
    jsonp(
      'https://viacep.com.br/ws/' + value.replace('-', '') + '/json',
      null,
      (error, response) => {
        if (response) {
          searchForCEPDelay(() => {
            this.props.handleChange('trading_address', response)
            this.setState({
              addressByCEP: response || {},
              isSearchingForAddress: false,
            })
          })
          return
        }
        this.setState({
          addressByCEP: {
            erro: true,
          },
          isSearchingForAddress: false,
        })
      },
    )
  }
}
