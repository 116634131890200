import React from 'react'

import {
  FormFieldText,
  FormFieldSelect,
  FormFieldSwitch,
} from 'components/lib/FormFields'
import FormGrid from 'components/lib/FormGrid'
import { fieldProps } from '../formCreator'
import { baseURL } from 'core/constants'
import request from 'axios'

class PersonalDataSpouse extends React.Component {
  constructor(props) {
    super(props)
    this.handleSpouseStatus = this.handleSpouseStatus.bind(this)
    this.state = {
      icomeOptions: [],
      spouse_status: false,
    }
  }

  componentDidMount() {
    request
      .get(`${baseURL}income-source`)
      .then((response) => {
        this.setState({ icomeOptions: response.data.data || response.data })
      })
      .catch((response) => {
        console.log(response)
      })

    const { defaultValue, fields } = this.props
    const spouse_status = fields.spouse_cpf
      ? true
      : defaultValue && defaultValue.spouse_cpf
      ? true
      : false

    if (!this.state.spouse_status && spouse_status) {
      this.setState({ spouse_status: spouse_status }, () => {
        this.props.handleChange
          ? this.props.handleChange('spouse_status', spouse_status)
          : null
      })
    }
  }

  // componentDidUpdate() {
  //   const { defaultValue, fields } = this.props

  //   // check spouse_status
  //   if (!fields) return false

  //   const spouse_status = fields.spouse_cpf
  //     ? true
  //     : defaultValue && defaultValue.spouse_cpf
  //     ? true
  //     : false

  //   if (!this.state.spouse_status && spouse_status) {
  //     this.setState({ spouse_status: spouse_status }, () => {
  //       this.props.handleChange
  //         ? this.props.handleChange('spouse_status', spouse_status)
  //         : null
  //     })
  //   }
  // }

  handleSpouseStatus() {
    const { spouse_status } = this.state

    this.setState({ spouse_status: !spouse_status }, () => {
      this.props.handleChange
        ? this.props.handleChange('spouse_status', !spouse_status)
        : null
    })

    if (spouse_status && this.props.updateFieldValues) {
      this.props.updateFieldValues([
        { fieldName: 'spouse_cpf', field: { value: '', isValid: true } },
        { fieldName: 'spouse_birth_date', field: { value: '', isValid: true } },
        { fieldName: 'spouse_email', field: { value: '', isValid: true } },
        { fieldName: 'spouse_identity', field: { value: '', isValid: true } },
        {
          fieldName: 'spouse_identity_issuing_body',
          field: { value: '', isValid: true },
        },
        {
          fieldName: 'spouse_identity_type',
          field: { value: '', isValid: true },
        },
        {
          fieldName: 'spouse_identity_expedition_date',
          field: { value: '', isValid: true },
        },
        { fieldName: 'spouse_name', field: { value: '', isValid: true } },
        {
          fieldName: 'spouse_nationality',
          field: { value: '', isValid: true },
        },
        { fieldName: 'spouse_occupation', field: { value: '', isValid: true } },
        { fieldName: 'spouse_phone', field: { value: '', isValid: true } },
        {
          fieldName: 'spouse_income_source_id',
          field: { value: '', isValid: true },
        },
        { fieldName: 'spouse_income', field: { value: '', isValid: true } },
      ])
    }
  }

  render() {
    const { spouse_status } = this.state
    const { fields, isMultiStep } = this.props

    let pageWidth = document.body.offsetWidth

    return !isMultiStep ? (
      <React.Fragment>
        <FormFieldSwitch
          type='checkbox'
          description='Tem cônjuge?'
          checked={spouse_status}
          onClick={this.handleSpouseStatus ? this.handleSpouseStatus : null}
        />
        {spouse_status ? (
          <React.Fragment>
            <FormGrid>
              <FormGrid column>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_name')}
                  label='Nome'
                  noValidate
                  mask={['upperCase']}
                  isRequired={this.props.isRequired}
                />
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_email')}
                  label='Email'
                  noValidate
                  mask={['lowerCase']}
                  isRequired={this.props.isRequired}
                />
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_phone')}
                  label='Telefone'
                  mask={['phone']}
                  noValidate
                  placeholder='XX XXXXX-XXXX'
                  isRequired={this.props.isRequired}
                  numbersOnly
                />
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_cpf')}
                  label='CPF'
                  mask={['cpf']}
                  noValidate
                  isRequired={this.props.isRequired}
                  numbersOnly
                />
                <FormFieldSelect
                  {...fieldProps(this.props, 'spouse_identity_type')}
                  label='Documento'
                  valueKey='name'
                  options={[
                    {
                      id: 1,
                      code: 'DOCUMENT_TYPE_RG',
                      name: 'RG',
                    },
                    // {
                    //   id: 2,
                    //   code: "DOCUMENT_TYPE_CNH",
                    //   name: "CNH"
                    // },
                    {
                      id: 3,
                      code: 'DOCUMENT_TYPE_PASSPORT',
                      name: 'Passaporte',
                    },
                    {
                      id: 4,
                      code: 'DOCUMENT_TYPE_OTHER',
                      name: 'Outro documento',
                    },
                  ]}
                  isRequired={this.props.isRequired}
                  noValidate
                />
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_identity')}
                  label='Número do documento'
                  mask={[{ maxLength: 15 }]}
                  isRequired={this.props.isRequired}
                  noValidate
                />
              </FormGrid>
              <FormGrid column>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_identity_issuing_body')}
                  label='Órgão emissor / UF'
                  noValidate
                  isRequired={this.props.isRequired}
                />
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_identity_expedition_date')}
                  label='Data de expedição do documento'
                  mask={['date']}
                  noValidate
                  placeholder='DD/MM/AAAA'
                  numbersOnly
                />
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_birth_date')}
                  label='Data de nascimento'
                  mask={['date']}
                  noValidate
                  placeholder='DD/MM/AAAA'
                  numbersOnly
                  isRequired={this.props.isRequired}
                />
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_nationality')}
                  label='Nacionalidade'
                  noValidate
                  isRequired={this.props.isRequired}
                />
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_occupation')}
                  label='Ocupação'
                  noValidate
                  isRequired={this.props.isRequired}
                />

                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_income')}
                    mask={['number']}
                    label='Renda'
                    noValidate
                    tabIndex='11'
                    isRequired={this.props.isRequired}
                  />
                </FormGrid>

                <FormFieldSelect
                  {...fieldProps(this.props, 'spouse_income_source_id')}
                  label='Fonte de renda'
                  valueKey='id'
                  options={this.state.icomeOptions}
                  noValidate
                  tabIndex='12'
                  isRequired={this.props.isRequired}
                />
              </FormGrid>
            </FormGrid>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    ) : pageWidth > 768 ? (
      <React.Fragment>
        <FormFieldSwitch
          type='checkbox'
          description='Tem cônjuge?'
          checked={spouse_status}
          onClick={this.handleSpouseStatus ? this.handleSpouseStatus : null}
        />
        {spouse_status ? (
          <React.Fragment>
            <FormGrid>
              <FormGrid column>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_name')}
                    onChange={(e) => this.props.handleChange('spouse_name', e)}
                    label='Nome'
                    noValidate
                    tabIndex='1'
                    mask={['upperCase']}
                    isRequired={this.props.isRequired}
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_phone')}
                    onChange={(e) => this.props.handleChange('spouse_phone', e)}
                    label='Telefone'
                    mask={['phone']}
                    noValidate
                    placeholder='XX XXXXX-XXXX'
                    tabIndex='3'
                    numbersOnly
                    isRequired={this.props.isRequired}
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_cpf')}
                    onChange={(e) => this.props.handleChange('spouse_cpf', e)}
                    label='CPF'
                    mask={['cpf']}
                    noValidate
                    tabIndex='5'
                    numbersOnly
                    isRequired={this.props.isRequired}
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldSelect
                    {...fieldProps(this.props, 'spouse_identity_type')}
                    onChange={(e) =>
                      this.props.handleChange('spouse_identity_type', e)
                    }
                    label='Documento'
                    valueKey='name'
                    options={[
                      {
                        id: 1,
                        code: 'DOCUMENT_TYPE_RG',
                        name: 'RG',
                      },
                      // {
                      //   id: 2,
                      //   code: "DOCUMENT_TYPE_CNH",
                      //   name: "CNH"
                      // },
                      {
                        id: 3,
                        code: 'DOCUMENT_TYPE_PASSPORT',
                        name: 'Passaporte',
                      },
                      {
                        id: 4,
                        code: 'DOCUMENT_TYPE_OTHER',
                        name: 'Outro documento',
                      },
                    ]}
                    noValidate
                    isRequired={this.props.isRequired}
                    tabIndex='7'
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_identity_issuing_body')}
                    onChange={(e) =>
                      this.props.handleChange('spouse_identity_issuing_body', e)
                    }
                    label='Órgão emissor / UF'
                    noValidate
                    tabIndex='9'
                    isRequired={this.props.isRequired}
                    mask={['upperCase']}
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_identity_expedition_date')}
                    onChange={(e) =>
                      this.props.handleChange('spouse_identity_expedition_date', e)
                    }
                    label='Data de expedição do documento'
                    mask={['date']}
                    noValidate
                    placeholder='DD/MM/AAAA'
                    numbersOnly
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_income')}
                    mask={['number']}
                    label='Renda'
                    noValidate
                    onChange={(e) => this.props.handleChange('spouse_income', e)}
                    isRequired={this.props.isRequired}
                    tabIndex='11'
                  />
                </FormGrid>
              </FormGrid>
              <FormGrid column>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_email')}
                    onChange={(e) => this.props.handleChange('spouse_email', e)}
                    label='Email'
                    noValidate
                    tabIndex='2'
                    isRequired={this.props.isRequired}
                    mask={['lowerCase']}
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_birth_date')}
                    onChange={(e) => this.props.handleChange('spouse_birth_date', e)}
                    label='Data de nascimento'
                    mask={['date']}
                    noValidate
                    placeholder='DD/MM/AAAA'
                    tabIndex='4'
                    numbersOnly
                    isRequired={this.props.isRequired}
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_nationality')}
                    onChange={(e) =>
                      this.props.handleChange('spouse_nationality', e)
                    }
                    label='Nacionalidade'
                    noValidate
                    tabIndex='6'
                    isRequired={this.props.isRequired}
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_identity')}
                    onChange={(e) => this.props.handleChange('spouse_identity', e)}
                    label='Número do documento'
                    mask={[{ maxLength: 15 }]}
                    noValidate
                    isRequired={this.props.isRequired}
                    tabIndex='8'
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldText
                    {...fieldProps(this.props, 'spouse_occupation')}
                    onChange={(e) => this.props.handleChange('spouse_occupation', e)}
                    label='Ocupação'
                    isRequired={this.props.isRequired}
                    noValidate
                    tabIndex='10'
                  />
                </FormGrid>
                <FormGrid field>
                  <FormFieldSelect
                    {...fieldProps(this.props, 'spouse_income_source_id')}
                    onChange={(e) =>
                      this.props.handleChange('spouse_income_source_id', e)
                    }
                    label='Fonte de renda'
                    valueKey='id'
                    options={this.state.icomeOptions}
                    isRequired={this.props.isRequired}
                    noValidate
                    tabIndex='12'
                  />
                </FormGrid>
              </FormGrid>
            </FormGrid>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <FormFieldSwitch
          type='checkbox'
          description='Tem cônjuge?'
          checked={spouse_status}
          onClick={this.handleSpouseStatus ? this.handleSpouseStatus : null}
        />
        {spouse_status ? (
          <React.Fragment>
            <FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_name')}
                  onChange={(e) => this.props.handleChange('spouse_name', e)}
                  label='Nome'
                  noValidate
                  isRequired={this.props.isRequired}
                  mask={['upperCase']}
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_email')}
                  onChange={(e) => this.props.handleChange('spouse_email', e)}
                  label='Email'
                  noValidate
                  isRequired={this.props.isRequired}
                  mask={['lowerCase']}
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_phone')}
                  onChange={(e) => this.props.handleChange('spouse_phone', e)}
                  label='Telefone'
                  mask={['phone']}
                  noValidate
                  isRequired={this.props.isRequired}
                  placeholder='XX XXXXX-XXXX'
                  numbersOnly
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_cpf')}
                  onChange={(e) => this.props.handleChange('spouse_cpf', e)}
                  label='CPF'
                  mask={['cpf']}
                  isRequired={this.props.isRequired}
                  noValidate
                  numbersOnly
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldSelect
                  {...fieldProps(this.props, 'spouse_identity_type')}
                  onChange={(e) =>
                    this.props.handleChange('spouse_identity_type', e)
                  }
                  label='Documento'
                  valueKey='name'
                  options={[
                    {
                      id: 1,
                      code: 'DOCUMENT_TYPE_RG',
                      name: 'RG',
                    },
                    // {
                    //   id: 2,
                    //   code: "DOCUMENT_TYPE_CNH",
                    //   name: "CNH"
                    // },
                    {
                      id: 3,
                      code: 'DOCUMENT_TYPE_PASSPORT',
                      name: 'Passaporte',
                    },
                    {
                      id: 4,
                      code: 'DOCUMENT_TYPE_OTHER',
                      name: 'Outro documento',
                    },
                  ]}
                  isRequired={this.props.isRequired}
                  noValidate
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_identity')}
                  onChange={(e) => this.props.handleChange('spouse_identity', e)}
                  label='Número do documento'
                  mask={[{ maxLength: 15 }]}
                  noValidate
                  isRequired={this.props.isRequired}
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_identity_issuing_body')}
                  onChange={(e) =>
                    this.props.handleChange('spouse_identity_issuing_body', e)
                  }
                  label='Órgão emissor / UF'
                  noValidate
                  mask={['upperCase']}
                  isRequired={this.props.isRequired}
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_identity_expedition_date')}
                  onChange={(e) =>
                    this.props.handleChange('spouse_identity_expedition_date', e)
                  }
                  label='Data de expedição do documento'
                  mask={['date']}
                  noValidate
                  placeholder='DD/MM/AAAA'
                  numbersOnly
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_birth_date')}
                  onChange={(e) => this.props.handleChange('spouse_birth_date', e)}
                  label='Data de nascimento'
                  mask={['date']}
                  noValidate
                  placeholder='DD/MM/AAAA'
                  numbersOnly
                  isRequired={this.props.isRequired}
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_nationality')}
                  onChange={(e) => this.props.handleChange('spouse_nationality', e)}
                  label='Nacionalidade'
                  noValidate
                  isRequired={this.props.isRequired}
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_occupation')}
                  onChange={(e) => this.props.handleChange('spouse_occupation', e)}
                  label='Ocupação'
                  noValidate
                  isRequired={this.props.isRequired}
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldText
                  {...fieldProps(this.props, 'spouse_income')}
                  mask={['number']}
                  label='Renda'
                  noValidate
                  onChange={(e) => this.props.handleChange('spouse_income', e)}
                  tabIndex='11'
                  isRequired={this.props.isRequired}
                />
              </FormGrid>
              <FormGrid field>
                <FormFieldSelect
                  {...fieldProps(this.props, 'spouse_income_source_id')}
                  onChange={(e) =>
                    this.props.handleChange('spouse_income_source_id', e)
                  }
                  label='Fonte de renda'
                  valueKey='id'
                  options={this.state.icomeOptions}
                  noValidate
                  tabIndex='12'
                  isRequired={this.props.isRequired}
                />
              </FormGrid>
            </FormGrid>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }
}

export default PersonalDataSpouse
