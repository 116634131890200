import React from 'react'

import ReactTimeout from 'react-timeout'
import { each } from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from './actions'

import Form from 'components/lib/Form'
import { invalidFields } from 'components/lib/FormFields'
import Modal from 'components/lib/Modal'

import parseApiErrors from 'utils/parseApiErrors'
import { getValues } from 'components/lib/FormFields'
import { deepKey, setFormInitialFocus } from 'utils/utils'

const formCreator = (Component, options = {}) => {
  class FormWrapper extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        fields: {},
        fieldErrors: {},
        formIsWorking: false,
        formFocus: false,
        modalTitle: '',
        modalIsOpen: false,
        feature: null,
      }
      this.form = null
      this.resetAfterSuccess = false
    }

    getSnapshotBeforeUpdate(prevProps) {
      if (prevProps.actionKey !== this.props.actionKey && this.state.formIsWorking) {
        this.setState({ formIsWorking: false })
        this.props.setTimeout(() => {
          this.setState({
            formIsWorking: false,
            fieldErrors: parseApiErrors(this.props.errors),
          })
          if (this.props.onSuccess) {
            if (this.resetAfterSuccess && this.props.isSuccess) this.resetForm()
            this.props.onSuccess({
              isSuccess: this.props.isSuccess,
              actionMessage: this.props.actionMessage,
              actionKey: this.props.actionKey,
              isProcessing: this.props.isProcessing,
              errors: this.props.errors,
              data: this.props.data,
            })
          }
        }, 300)
      }

      return null
    }

    componentDidUpdate() {}

    render() {
      const formIsWorking = this.state.formIsWorking
      const formIsReady = invalidFields(this.state.fields).length === 0

      return (
        <>
          {!options.noForm ? (
            <Form
              getRef={(ref) => (this.form = ref)}
              onSubmit={this.submitForm.bind(this)}
              onFocus={this.handleFieldFocus.bind(this)}
              autoFocus={!this.props.disabledAutoFocus}
            >
              <Component
                formIsWorking={formIsWorking}
                formIsReady={formIsReady}
                defaultValue={this.props.defaultValue || {}}
                handleFieldChanges={this.handleFieldChanges.bind(this)}
                updateFieldValues={this.updateFieldValues.bind(this)}
                goto={this.goto.bind(this)}
                submitForm={this.submitForm.bind(this)}
                createRequest={this.createRequest.bind(this)}
                cancelProgress={this.cancelProgress.bind(this)}
                onSubmit={this.submitForm.bind(this)}
                {...this.props}
                {...this.state}
              />
            </Form>
          ) : (
            <Component
              formIsWorking={formIsWorking}
              formIsReady={formIsReady}
              defaultValue={this.props.defaultValue || {}}
              handleFieldChanges={this.handleFieldChanges.bind(this)}
              updateFieldValues={this.updateFieldValues.bind(this)}
              goto={this.goto.bind(this)}
              submitForm={this.submitForm.bind(this)}
              createRequest={this.createRequest.bind(this)}
              cancelProgress={this.cancelProgress.bind(this)}
              {...this.props}
              {...this.state}
            />
          )}

          <Modal
            title={this.state.modalTitle}
            isOpen={this.state.modalIsOpen}
            onClose={this.handleModalClose.bind(this)}
          >
            {this.state.feature}
          </Modal>
        </>
      )
    }

    updateFieldValues(newFields, cb, cbParams) {
      let { fields } = this.state

      each(newFields, (it) => {
        fields[it.fieldName] = it.field
      })
      this.setState(
        {
          fields: fields,
          hideAlert: true,
          formFocus: true,
        },
        typeof cb === 'function' ? cb(cbParams) : null,
      )
    }

    handleFieldChanges(name, value, field) {
      let fields = this.state.fields

      fields[name] = field
      let fieldError = this.state.fieldErrors

      delete fieldError[name]
      this.setState({
        fields: fields,
        hideAlert: true,
        formFocus: true,
        fieldErrors: fieldError,
      })
      if (options.submitOnChange) this.submitForm()
    }

    handleFieldFocus({ target }) {
      if (!this.state.formFocus) {
        this.setState({
          formFocus: true,
          formElement: target,
        })
      }
    }

    handleModalClose() {
      this.setState({
        modalTitle: '',
        modalIsOpen: false,
        feature: null,
      })
    }

    handleFeature(modalTitle, feature) {
      this.setState({
        modalTitle,
        modalIsOpen: true,
        feature,
      })
    }

    goto(path) {
      this.props.history.push(path)
    }

    cancelProgress() {
      this.setState({
        formIsWorking: false,
      })
    }

    createRequest(request, resetAfterSuccess) {
      this.request = request
      this.resetAfterSuccess = resetAfterSuccess
    }

    handleFormErrors(fields) {
      const guarantorModalTitle = 'Por favor preencha o formulário corretamente'
      const guarantorModalBody =
        'Todos os dados de contato do fiador são obrigatórios'

      let isGuarantorError = false

      fields.map((field) => {
        if (field.includes('guarantor')) {
          isGuarantorError = true

          return
        }
      })
      if (isGuarantorError) {
        this.handleFeature(guarantorModalTitle, guarantorModalBody)

        return true
      }
      // this.handleFeature(genericModalTitle, genericModalBody);

      return false
    }

    submitForm(e) {
      if (e && options.stopPropagation) e.stopPropagation()

      if (this.state.formFocus || options.noForm) {
        this.setState({ formIsWorking: true, formFocus: false })
        if (this.state.formElement) this.state.formElement.blur()
        if (typeof this.request === 'function')
          this.request(getValues(this.state.fields), this.state.fields)
        if (typeof this.props.onSubmit === 'function')
          this.props.onSubmit(getValues(this.state.fields), this.state.fields)
      }
    }

    resetForm() {
      this.form ? this.form.reset() : null
      this.setState({
        fields: {},
        fieldErrors: {},
        formIsWorking: false,
        formFocus: false,
      })
      setFormInitialFocus(this.form)
    }
  }

  FormWrapper.displayName = `FormWrapper(${getDisplayName(Component)})`

  function mapStateToProps(store) {
    return {
      isSuccess: store.features.isSuccess,
      actionMessage: store.features.actionMessage,
      actionKey: store.features.actionKey,
      isProcessing: store.features.isProcessing,
      errors: store.features.errors,
      data: store.features.data,
    }
  }

  function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(FormWrapper))
}

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component'
}

export const fieldProps = (props, fieldName) => ({
  name: fieldName,
  valueDefault: props.defaultValue ? deepKey(props.defaultValue, fieldName) : '',
  value: props.fields ? deepKey(props.fields, `${fieldName}.value`) || '' : '',
  checkedDefault: props.defaultValue
    ? deepKey(props.defaultValue, fieldName) || false
    : false,
  checked: props.fields
    ? deepKey(props.fields, `${fieldName}.value`) || false
    : false,
  isDisabled: props.formIsWorking || props.isDisabled,
  forceInvalid: props.isDeep
    ? props.errors.find((error) => error.field === fieldName)
    : props.fieldErrors
      ? deepKey(props.fieldErrors, fieldName)
      : null,
  errorMessage: props.isDeep
    ? props.errors.find((error) => error.field === fieldName)?.message
    : props.fieldErrors
      ? deepKey(props.fieldErrors, fieldName)
      : null,
  onChange:
    props.handleFieldChanges && props.handleFieldChanges.bind(null, fieldName),
})

export default formCreator
