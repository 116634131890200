import React from "react";
import Button from "components/lib/Button";
import Icon from "components/lib/Icon";
import * as types from "../types";

class FileName extends React.Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    const { content, row } = this.props;
    return (
    <React.Fragment>
      {row.file_name ? 
      <span onClick={() => this.props.handleViewDocument(row)} style={{wordBreak: 'break-all', cursor: 'pointer'}}> <Icon name="fas fa-eye"></Icon> {row.file_name} </span>
      : null }
      </React.Fragment>
    );
  }
}
export default FileName;
