import React, { useState } from "react";

import request from "axios";

import * as types from "../types";
import { Input, InputContainer, Loading } from "./styles";
import Icon from "components/lib/Icon";

const FileButton = props => {
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const uploadDocument = event => {
    if (!event) return;

    setUploading(true);

    const { docsUrl, row, entityName, bearerToken } = props;
    const file = event.target.files[0];
    let dbEntityId = "";

    switch (entityName) {
      case "proposal":
        dbEntityId = 1;
        break;
      case "property":
        dbEntityId = 2;
        break;
      case "propertyOwner":
        dbEntityId = 3;
        break;
      case "client":
        dbEntityId = 4;
        break;
      case "guarantor":
        dbEntityId = 5;
        break;
      case "processPossession":
        dbEntityId = 6;
        break;
      case "processInsurance":
        dbEntityId = 7;
        break;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("status", "STATUS_ANALYSIS");
    formData.append("id", props.row.id);
    formData.append("name", row.name);
    formData.append("entity_id", dbEntityId);
    formData.append("owner_document", props.entityId);

    const config = bearerToken
      ? {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Access-Control-Allow-Origin": true
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        }
      }
      : {
        "Access-Control-Allow-Origin": true,
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        }
      };

    request
      .post(docsUrl, formData, config)
      .then(response => {
        console.log("res", response);
        setUploading(false);
        props.refresh();
      })
      .catch(err => {
        console.log("err", err);
        setUploading(false);
        props.refresh();
      });
  };

  const { row, isMobile, className } = props;

  const { status } = row;

  let button = null;

  const label = props.label || null;
  const showIcon = props.showIcon;

  switch (status) {
    case types.STATUS_WAITING:
      button = (
        <InputContainer isMobile={isMobile}>
          <Input
            type="file"
            id={`document_${row.id}`}
            onChange={uploadDocument}
          />
          <label className={className} htmlFor={`document_${row.id}`}>
            {label ? label : 'Enviar documento'} {(showIcon || typeof showIcon == 'undefined') && (<Icon name="fas fa-upload" />)}
          </label>
        </InputContainer>
      );
      break;
    case types.STATUS_APPROVED:
    case types.STATUS_ANALYSIS:
    case types.STATUS_REJECTED:
      button = (
        <InputContainer documentSaved isMobile={isMobile}>
          <Input
            type="file"
            id={`document_${row.id}`}
            onChange={uploadDocument}

          />
          <label className={className} htmlFor={`document_${row.id}`}>
            {label ? label : 'Reenviar Documento'} {(showIcon || typeof showIcon == 'undefined') && (<Icon name="fas fa-upload" />)}
          </label>
        </InputContainer>
      );
  }

  if (uploading) {
    button = (
      <InputContainer isMobile={isMobile}>
        <Loading>Carregando... {progress}%</Loading>
      </InputContainer>
    );
  }

  return button;
};

export default FileButton;
