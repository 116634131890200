import React from 'react';
import ReactTimeout from 'react-timeout';
// import PropTypes from 'prop-types'; CHRISTIAN FALOU QUE VAI USAR ISSO DEPOIS, QUERO SÓ VER

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';

// Components
import Alert from 'components/Alert';
import Layout from 'components/lib/Layout';
import Box from 'components/lib/Box';
import Form from 'components/lib/Form';
import SplashScreen from 'components/lib/SplashScreen';

import { toParams } from './OAuth2/utils';

// Utils
import parseApiErrors from 'utils/parseApiErrors';

const authCreator = (Component) => {
  class AuthWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        fields: {},
        hideAlert: true,
        formIsWorking: false,
        splashScreenIsVisible: false,
      };
    }

    componentDidMount() {
      const params = toParams(window.location.hash);
      if (params.access_token) {
        this.loginOauth(params);
      }
    }

    getSnapshotBeforeUpdate(prevProps) {
      if (prevProps.actionKey !== this.props.actionKey) {
        this.props.setTimeout(
          () =>
            this.setState({
              formIsWorking: false,
              hideAlert: false,
              fieldHasError: (this.props.errors || []).length > 0,
            }),
          300
        );
      }
      return null;
    }

    render() {
      const formIsWorking = this.state.formIsWorking;
      let fieldErrors = parseApiErrors(this.props.errors);

      return (
        <Layout isVhCentered hasNeutralBackground>
          <Alert
            isWarning={!this.props.isSuccess}
            isSuccess={this.props.isSuccess}
            isInvisible={this.state.hideAlert || !this.props.actionMessage}
            isFixed
            isFixedTopRight
            onClose={this.handleAlertClosing.bind(this)}>
            <strong className="has-text-white">{this.props.actionMessage}</strong>
          </Alert>

          <SplashScreen isVisible={this.state.splashScreenIsVisible} showSpinner />

          <Box maxWidth={620}>
            <Form onSubmit={this.submitForm.bind(this)} autoFocus>
              <Component
                formIsWorking={formIsWorking}
                handleFormField={this.handleFormField.bind(this)}
                handleAlertClosing={this.handleAlertClosing.bind(this)}
                goto={this.goto.bind(this)}
                submitForm={this.submitForm.bind(this)}
                createRequest={this.createRequest.bind(this)}
                showSplashScreen={this.showSplashScreen.bind(this)}
                fieldErrors={fieldErrors}
                {...this.props}
                {...this.state}
              />
            </Form>
          </Box>
        </Layout>
      );
    }

    handleFormField(name, value, field) {
      const fields = { ...this.state.fields, ...{ [name]: field } };
      this.setState({
        fields: fields,
        hideAlert: true,
        fieldHasError: false,
      });
      // Store email to use on similar screens
      if (name === 'username' && field.isValid) {
        this.props.saveEmail(field.value);
      }
    }

    handleAlertClosing() {
      this.setState({ hideAlert: true });
    }

    goto(path) {
      this.props.history.push(path);
    }

    showSplashScreen(isVisible) {
      this.setState({ splashScreenIsVisible: isVisible });
    }

    createRequest(request) {
      this.request = request;
    }

    submitForm() {
      this.setState({
        hideAlert: true,
        formIsWorking: true,
      });

      if (typeof this.request === 'function') this.request(this.state.fields);
    }

    loginOauth(response) {
      localStorage.setItem('izee-token', response.access_token);
      this.props.history.push('/');
    }
  }

  AuthWrapper.displayName = `AuthWrapper(${getDisplayName(Component)})`;

  function mapStateToProps(store) {
    return {
      actionKey: store.auth.actionKey,
      actionMessage: store.auth.actionMessage,
      isSuccess: store.auth.isSuccess,
      errors: store.auth.errors,
      loginData: store.auth.loginData,
      data: store.auth.data,
      savedEmail: store.auth.savedEmail,
      userExists: store.auth.userExists,
    };
  }

  function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(actions, dispatch) };
  }

  return connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(AuthWrapper));
};

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export default authCreator;
