import React from 'react'

import Dropdown from 'components/lib/Dropdown'
import Icon from 'components/lib/Icon'

import IconAppsMenu from 'static/media/apps.png'
import RedeConfiax from 'static/media/rede-confiax.png'
import RedeVistorias from 'static/media/rede-vistorias.png'
import RedeIzeeDisabled from 'static/media/rede-izee-disabled.png'
import GrupoRv from 'static/media/grupo-rv.png'

const RedeVistoriasUrl = APP_REDE_VISTORIAS_URL
const RedeConfiaxUrl = APP_REDE_CONFIAX_URL
const ClientAccountUrl = APP_CLIENT_ACCOUNT_URL

const AppsMenu = () => {
  const redirectApp = (url) => {
    const token = localStorage.getItem('izee-token')

    window.open(
      `${url}#access_token=${token}&token_type=Bearer&expires_in=`,
      '_blank',
    )
  }

  return (
    <Dropdown
      isRight
      trigger={
        <figure className='image is-brand is-36px'>
          <img src={IconAppsMenu} />
        </figure>
      }
    >
      <Dropdown.Item hasDivider>
        <div className='is-flex justify-between'>
          <span className='app-option-title'>Soluções Grupo RV</span>

          <Icon name='fas fa-times' isDefault />
        </div>
      </Dropdown.Item>

      <Dropdown.Item>
        <div className='is-flex flex-wrap justify-between'>
          <div className='app-option'>
            <div className='flex-center' style={{ width: 70, height: 48 }}>
              <img
                src={RedeIzeeDisabled}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>

            <span className='app-option-text'>cadastros e contratos</span>
          </div>
          {RedeConfiaxUrl && (
            <div className='app-option' onClick={() => redirectApp(RedeConfiaxUrl)}>
              <div className='flex-center' style={{ width: 70, height: 48 }}>
                <img
                  src={RedeConfiax}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </div>

              <span className='app-option-text'>seguros</span>
            </div>
          )}
          {RedeVistoriasUrl && (
            <div
              className='app-option'
              onClick={() => redirectApp(RedeVistoriasUrl)}
            >
              <div className='flex-center' style={{ width: 70, height: 48 }}>
                <img
                  src={RedeVistorias}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </div>

              <span className='app-option-text'>vistorias</span>
            </div>
          )}
          {ClientAccountUrl && (
            <div
              className='app-option'
              onClick={() => redirectApp(ClientAccountUrl)}
            >
              <div className='flex-center' style={{ width: 70, height: 48 }}>
                <img src={GrupoRv} style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </div>

              <span className='app-option-text'>Área do cliente</span>
            </div>
          )}
        </div>
      </Dropdown.Item>
    </Dropdown>
  )
}

export default AppsMenu
