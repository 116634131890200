import { request } from 'core/constants';

export const formIsProcessing = (is) => (dispatch) => {
  dispatch({
    type: 'SUBMIT_FORM_IS_PROCESSING',
    payload: is
  });
};

export const submit = (path, body, method, options) => (dispatch, store) => {
  dispatch(formIsProcessing(true));

  if (path.match('{company}')) {
    const companyId = store().app.activeCompany.id;
    path = path.replace('{company}', `company/${companyId}`);
  }

  return request[method ? method : 'post'](path, body, options)
    .then((response) => {
      dispatch({
        type: 'SUBMIT_FORM_SUCCESS',
        payload: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: 'SUBMIT_FORM_ERROR',
        payload: error,
      });
      return error;
    });
};


export const fakeSubmit = (payload) => (dispatch) => {
  dispatch({
    type: 'SUBMIT_FORM_SUCCESS',
    payload: {
      success: true,
      payload: payload
    },
  });
};
