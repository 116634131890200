export const DOCUMENT_LIST_ID = Object.freeze({
  CREATE_PROPOSAL_CLIENT: 1,
  CREATE_PROPOSAL_GUARANTOR: 2,
  SOLICITATION_OWNER: 3,
  SOLICITATION_CLIENT: 4,
  CREATE_OWNER: 5,
  CREATE_CLIENT: 6,
  CREATE_GUARANTOR: 7,
  CREATE_PROPOSAL_OWNER: 8,
})

export const LIST_OPTIONS = [
  {
    id: DOCUMENT_LIST_ID.CREATE_PROPOSAL_CLIENT,
    label: 'Proposta: Seleção de cliente',
  },
  {
    id: DOCUMENT_LIST_ID.CREATE_PROPOSAL_GUARANTOR,
    label: 'Proposta: Seleção de fiador',
  },
  {
    id: DOCUMENT_LIST_ID.SOLICITATION_OWNER,
    label: 'Cadastros: Solicitação de novo proprietário',
  },
  {
    id: DOCUMENT_LIST_ID.SOLICITATION_CLIENT,
    label: 'Cadastros: Solicitação de novo(a) cliente',
  },
  {
    id: DOCUMENT_LIST_ID.CREATE_OWNER,
    label: 'Cadastros: Cadastro de novo proprietário',
  },
  {
    id: DOCUMENT_LIST_ID.CREATE_CLIENT,
    label: 'Cadastros: Cadastro de novo(a) cliente',
  },
  {
    id: DOCUMENT_LIST_ID.CREATE_GUARANTOR,
    label: 'Cadastros: Cadastro de novo fiador',
  },
  {
    id: DOCUMENT_LIST_ID.CREATE_PROPOSAL_OWNER,
    label: 'Captação: Seleção de proprietário',
  },
]
