import React, { useState } from 'react'
import withRedux from 'components/redux'
import { ROLE } from 'components/constants'

import { FormFieldText } from 'components/lib/FormFields'
import FlexBar from 'components/lib/FlexBar'
import Dropdown from 'components/lib/Dropdown'
import Icon from 'components/lib/Icon'
import Button from 'components/lib/Button'
import Layout from 'components/lib/Layout'
import AppLogo from 'components/AppLogo'
import AppsMenu from 'components/AppsMenu'

import gravatar from 'gravatar'

import MoviDesk from './MoviDesk'
import Faq from './Faq'

const MainHeader = (props) => {
  let { activeUser, activeCompany, forbiddenAccess } = props
  const profiles = activeCompany && activeCompany.profiles

  activeUser = activeUser || {}
  activeCompany = activeCompany || MainHeader.defaultProps.activeCompany

  if (!activeCompany.id) {
    return <></>
  }

  const sortedCompanies = (activeUser?.companies || [])
    .filter((item) => activeCompany.id !== item.id)
    .sort((a, b) => a.name.localeCompare(b.name))

  const [filteredCompanies, setFilteredCompanies] = useState(
    sortedCompanies.map((item) => (
      <li
        onClick={props.onChangeCompany.bind(null, item)}
        onKeyDown={props.onChangeCompany.bind(null, item)}
        tabIndex='0'
        role='button'
        key={item.id}
      >
        <Button isBare isFullWidth onClick={props.onChangeCompany.bind(null, item)}>
          <Icon name='far fa-building' /> {item.name}
        </Button>
      </li>
    )),
  )

  const handleFilterCompanies = (v) => {
    const filtered = sortedCompanies
      .filter((item) =>
        String(item.name)
          .toLocaleLowerCase()
          .includes(String(v).toLocaleLowerCase()),
      )
      .map((item) => (
        <li
          onClick={props.onChangeCompany.bind(null, item)}
          onKeyDown={props.onChangeCompany.bind(null, item)}
          tabIndex='0'
          role='button'
          key={item.id}
        >
          <Button
            isBare
            isFullWidth
            onClick={props.onChangeCompany.bind(null, item)}
          >
            <Icon name='far fa-building' /> {item.name}
          </Button>
        </li>
      ))

    setFilteredCompanies(filtered)
  }

  const avatar =
    activeUser.avatar || gravatar.url(activeUser.email, { size: 64, d: 'mp' })

  const canAccessLocation = true
  const canAccessRegister = !forbiddenAccess([
    ROLE.PROFILE_INSPECTOR,
    ROLE.PROFILE_JURIDICAL,
  ])
  const canAccessSettings = !forbiddenAccess([
    ROLE.PROFILE_AGENT,
    ROLE.PROFILE_INSPECTOR,
    ROLE.PROFILE_JURIDICAL,
  ])

  const handleLogoClick = () => {
    const recordsHome =
      activeCompany.subscription_plan?.maximum_parallel_processes === 0
    const home = recordsHome ? 'records/guests' : '/'

    props.goto(home)
  }

  return (
    <Layout.Header hasThemeColor>
      <FlexBar specSelector='app-header' isLarge>
        <FlexBar.Child>
          <div onClick={handleLogoClick}>
            <AppLogo name={activeCompany.name} responsive />
          </div>
        </FlexBar.Child>

        <FlexBar.Child>
          <Faq />
          <MoviDesk />

          <Dropdown
            keepOpen
            isRight
            trigger={
              <figure className='image is-brand has-border-rounded is-36px'>
                <img src={avatar} alt={activeUser.name || activeUser.email} />
              </figure>
            }
          >
            <React.Fragment>
              <Dropdown.Item
                specSelector='user-profile-button'
                onClick={props.goto.bind(this, 'profile')}
              >
                <div className='media'>
                  <div className='media-left'>
                    <figure className='image is-48px has-border-rounded'>
                      <img src={avatar} alt={activeUser.name || activeUser.email} />
                    </figure>
                  </div>
                  <div
                    className='media-content is-flex'
                    style={{ flexDirection: 'column' }}
                  >
                    <span className='text is-size-6'>
                      <strong>
                        {!activeUser.name ? (
                          <React.Fragment>
                            Usuário&nbsp;
                            <Icon name='far fa-pencil' isInfo isSmall />
                          </React.Fragment>
                        ) : (
                          activeUser.name
                        )}
                      </strong>
                    </span>
                    <span className='text is-size-7 has-text-grey'>
                      {activeUser.email}
                    </span>
                    {profiles && (
                      <span className='text is-size-7 has-text-grey'>
                        {profiles.length > 1 ? 'Papéis' : 'Papel'}:{' '}
                        {profiles.map((x) => x.name).join(', ')}
                      </span>
                    )}
                  </div>
                </div>
              </Dropdown.Item>

              <Dropdown.Item hasDivider />

              <Dropdown.Item isFeatured>
                <div className='company-card'>
                  <div className='company-card-header'>
                    <Icon name='far fa-building' />
                    {activeCompany.name}
                  </div>

                  <div className='company-card-actions buttons'>
                    {canAccessLocation && (
                      <Button
                        onClick={props.goto.bind(this, '/')}
                        isSelected
                        isTab
                        isFlat
                        isFullWidth
                      >
                        Locações e Vendas
                      </Button>
                    )}
                    {canAccessRegister && (
                      <Button
                        onClick={props.goto.bind(this, 'records/property-owners')}
                        isSelected
                        isTab
                        isFlat
                        isFullWidth
                      >
                        Cadastros
                      </Button>
                    )}
                    {canAccessSettings && (
                      <Button
                        onClick={props.goto.bind(this, 'settings/general')}
                        hasThemeColor
                        hasIconOnly
                        isFlat
                      >
                        <Icon name='far fa-cog' />
                      </Button>
                    )}
                  </div>
                </div>
              </Dropdown.Item>

              <Dropdown.Item style={{ maxHeight: '45vh', overflow: 'hidden' }}>
                {sortedCompanies.length >= 1 ? (
                  <div className='menu' style={{ marginTop: 18 }}>
                    <p className='menu-label'>
                      Mais empresas ({filteredCompanies.length})
                    </p>
                    <FormFieldText
                      placeholder='Pesquisar'
                      onChange={handleFilterCompanies}
                      noValidate
                    />
                    <ul
                      className='menu-list'
                      style={{
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                        maxHeight: 'calc(45vh - 100px)',
                      }}
                    >
                      {filteredCompanies}
                    </ul>
                  </div>
                ) : null}
              </Dropdown.Item>

              <Dropdown.Item hasDivider />

              <Dropdown.Item onClick={props.onClickLogout}>
                <Button
                  isBare
                  isFullWidth
                  isMedium
                  specSelector='logout-button'
                  onClick={props.onClickLogout}
                >
                  <Icon name='far fa-sign-out' isDanger />
                  Sair
                </Button>
              </Dropdown.Item>
            </React.Fragment>
          </Dropdown>

          <AppsMenu />
        </FlexBar.Child>
      </FlexBar>
    </Layout.Header>
  )
}

MainHeader.defaultProps = {
  activeCompany: {
    id: null,
    owner: {},
  },
}

// export default MainHeader;
export default withRedux({})(MainHeader)
