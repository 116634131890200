import { uuid } from 'utils/utils';

const initialState = {
  isSuccess: null,
  actionMessage: '',
  actionKey: uuid(),
  isProcessing: false,
  errors: [],
  data: null
};

export default (state = initialState, action = {}) => {

  switch (action.type) {

  case 'SUBMIT_FORM_IS_PROCESSING':
    state = {
      ...state,
      ...{ isProcessing: true }
    };
    break;

  case 'SUBMIT_FORM_SUCCESS':
    state = {
      ...state,
      ...{
        isSuccess: true,
        actionMessage: action?.payload?.message,
        actionKey: uuid(),
        isProcessing: false,
        errors: [],
        data: action?.payload
      }
    };
    break;

  case 'SUBMIT_FORM_ERROR':
    state = {
      ...state,
      ...{
        isSuccess: false,
        actionMessage: action?.payload?.message,
        actionKey: uuid(),
        isProcessing: false,
        errors: action?.payload?.errors,
        data: action?.payload
      }
    };
    break;

  default:
    break;
  }

  return state;

};
