import React from "react";

import Table from "components/lib/Table";
import FileButtons from "./FileButtons";
import StatusField from "./StatusField";

import FileButton from "./FileButton";
import FileName from "./FileName";
import { FormFieldCheckbox } from "components/lib/FormFields";

import {
  STATUS_WAITING,
  STATUS_ANALYSIS,
  STATUS_APPROVED,
  STATUS_REJECTED
} from "../types";

class Component extends React.Component {
  renderButtons(row) {
    // return <FileButtons {...this.props} row={row} />
    return <FileButton {...this.props} row={row}></FileButton>;
  }
  renderCheckBox(content) {
    const { selected } = this.props;
    const checked = selected.indexOf(content) === -1 ? false : true;
    return (
      <FormFieldCheckbox
        checked={checked}
        onClick={() => this.props.handleSelectRow(content)}
      />
      // <i
      //   className={
      //     checked ? 'check-icon fas fa-square' : 'check-icon fal fa-square'
      //   }
      //   onClick={() => this.props.handleSelectRow(content)}
      // />
    );
  }
  handleRowClick(row) {
    window.open(row.new_source, "_blank");
  }
  renderStatus(content, row) {
    return <StatusField content={content} row={row} {...this.props} />;
  }

  renderFileName(content, row) {
    return <FileName content={content} row={row} {...this.props} />;
  }

  render() {
    const { items, pageWidth, allChecked, hideUpload = false } = this.props;
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (!items[i].index) {
          switch (items[i].status) {
            case STATUS_ANALYSIS:
              items[i].index = 0;
              break;
            case STATUS_APPROVED:
              items[i].index = 1;
              break;
            case STATUS_WAITING:
              items[i].index = 2;
              break;
            case STATUS_REJECTED:
              items[i].index = 3;
              break;
          }
        }
      }

      items.sort((a, b) => a.index - b.index);
    }
    const cells = [
      {
        name: ( document.body.offsetWidth < 1025 ? 'Selecionar' :
          <FormFieldCheckbox
            checked={allChecked}
            onClick={() => {
              this.props.handleSelectAll();
            }}
          />
        ),
        render: (content) => this.renderCheckBox(content)
      },
      {
        name: "Documentos",
        width: "20%",
        render: content => {
          return content;
        }
      },
      {
        name: "Status",
        width: "25%",
        render: (content, row) => this.renderStatus(content, row)
      },
      {
        name: "Arquivo",
        width: "30%",
        render: (content, row) => this.renderFileName(content, row)
      },
      {
        name: "Enviar",
        width: "25%",
        render: (content, row) => this.renderButtons(row),
        remove: hideUpload
      }
    ].filter(x => !x.remove)

    return (
      <Table
        items={items}
        cells={cells}
        emptyState={items.length > 0 ? true : <div><p>Não há documentos solicitados.</p> <br></br> <p>Você pode escrever o nome do documento no campo acima e clicar no sinal de +</p></div>}
      />
    );
  }
}
export default Component;
