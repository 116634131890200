import AppWrapper from 'containers/AppWrapper'
import Process from 'containers/Process'

import Profile from 'containers/Profile'

import Settings from 'containers/Settings'
import SettingsRoutes from 'containers/Settings/routes'

import Records from 'containers/Records'
import RecordsRoutes from 'containers/Records/routes'

import ProcessNew from 'containers/ProcessNew'
import ProcessDetails from 'containers/ProcessDetails'
import ProcessRoutes from 'containers/ProcessDetails/routes'

import Marketplace from 'containers/Marketplace'
import MarketplaceRoutes from 'containers/Marketplace/routes'

import Proposal from 'containers/Proposal'
import ProposalNew from 'containers/Proposal/components/New'

import Dashboard from 'containers/Dashboard'

const routes = [
  {
    name: 'App Home',
    path: '/app/:userId/:companyId?',
    component: AppWrapper,
    routes: [
      {
        name: 'Process',
        path: '/app/:userId/:companyId',
        exact: true,
        component: Process,
      },
      {
        name: 'PropertyCapture',
        path: '/app/:userId/:companyId/property-capture',
        exact: true,
        component: Process,
      },
      {
        name: 'New Process',
        path: '/app/:userId/:companyId/process/new',
        exact: true,
        component: ProcessNew,
      },
      {
        name: 'New Proposal',
        path: '/app/:userId/:companyId/proposal/new',
        exact: true,
        component: ProposalNew,
      },
      {
        name: 'Process Details',
        path: '/app/:userId/:companyId/process/:processId/:view?',
        component: ProcessDetails,
        routes: ProcessRoutes,
      },
      {
        name: 'Proposal',
        path: '/app/:userId/:companyId/proposal/:proposalId',
        exact: true,
        component: Proposal,
      },
      {
        name: 'Profile',
        path: '/app/:userId/:companyId/profile',
        exact: true,
        component: Profile,
      },
      {
        name: 'Company Settings',
        path: '/app/:userId/:companyId/settings/:view?',
        component: Settings,
        routes: SettingsRoutes,
      },
      {
        name: 'Records',
        path: '/app/:userId/:companyId/records/:view?',
        component: Records,
        routes: RecordsRoutes,
      },
      {
        name: 'Marketplace',
        path: '/app/:userId/:companyId/services/:view?',
        component: Marketplace,
        routes: MarketplaceRoutes,
      },
      {
        name: 'Dashboard',
        path: '/app/:userId/:companyId/dashboard',
        component: Dashboard,
      },
    ],
  },
]

export default routes
