import React from 'react'
import ReactTimeout from 'react-timeout'
import request from 'axios'
import { baseURL } from 'core/constants'
import { fb, ga } from 'izee-app/src/services/firebase'
import * as commons from 'utils/UtilityStore/actions'
import Button from 'components/lib/Button'
import Modal from 'components/lib/Modal'
import Icon from 'components/lib/Icon'
import withModal from 'components/HOCs/withModal'
import Profile from 'components/Profile'
import FlexBar from 'components/lib/FlexBar'
import LinkProposal from 'containers/Proposal/components/LinkProposal'
import ProposalNew from 'containers/Proposal/components/New'
import { ListMenu, Menu } from './styles'

export class ScreenHeaderPrimary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guarantorsIds: (this.props.defaultValue || {}).guarantorsIds,
    }
  }

  render() {
    const subscriptionPlan = this.props.company?.subscription_plan

    return (
      <>
        <FlexBar isSecondary hasNeutralBackground hasShadow>
          <FlexBar.Child isGrow>
            <ListMenu>
              <Menu
                onClick={this.gotoDashboard.bind(this)}
                className={this.props?.name === 'Dashboard' ? 'active' : ''}
              >
                Dashboard
                {subscriptionPlan && !subscriptionPlan?.can_access_dashboard ? (
                  <Icon isSmall name='fas fa-star is-warning' />
                ) : (
                  ''
                )}
              </Menu>
              <Menu
                className={this.props?.name === 'Process' ? 'active' : ''}
                onClick={this.gotoRentalsAndSales.bind(this)}
              >
                Locações e vendas
              </Menu>
              <Menu
                onClick={this.gotoPropertyCapture.bind(this)}
                className={this.props?.name === 'PropertyCapture' ? 'active' : ''}
              >
                Captação de imóveis
              </Menu>
              <Menu
                onClick={this.gotoMarketplace.bind(this)}
                className={
                  this.props?.name === 'Analysis' ||
                  this.props?.name === 'Marketplace'
                    ? 'active'
                    : ''
                }
              >
                Serviços
              </Menu>
            </ListMenu>
          </FlexBar.Child>
          <FlexBar.Child>
            <Button onClick={this.linkNewProposal.bind(this)} isFlat hasThemeColor>
              Link de proposta
            </Button>

            <Profile disabledIf={[6, 7]}>
              <Button
                onClick={this.createNewProposal.bind(this)}
                specSelector='new-proposal'
                isFlat
                hasThemeColor
              >
                Nova proposta
              </Button>
            </Profile>
            <Profile disabledIf={[6, 7]}>
              <Button onClick={this.gotoRecords.bind(this)} isFlat isTab isSelected>
                Cadastros
              </Button>
            </Profile>
          </FlexBar.Child>
        </FlexBar>

        <Modal
          title={this.state.modalTitle}
          isOpen={this.state.modalIsOpen}
          onClose={this.handleModalClose.bind(this)}
        >
          {this.state.feature}
        </Modal>
      </>
    )
  }

  handleModalClose() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    })
  }

  createNewProposal() {
    const { activeCompany, proposal, companyId } = this.props

    this.props.openModal(
      'Nova proposta',
      <ProposalNew
        {...this.props}
        activeCompanyModel={activeCompany}
        activeCompany={companyId && companyId}
        defaultValue={proposal}
        onSelectGuarantor={this.onSelectGuarantor.bind(this)}
        onSuccess={this.onCreateProposalSuccess.bind(this)}
        proposalId={proposal && proposal.id}
      />,
    )
  }

  linkNewProposal() {
    const { activeCompany } = this.props

    this.props.openModal(
      'Link de proposta',
      <LinkProposal
        {...this.props}
        activeCompany={activeCompany}
        onClickToClose={this.props.closeModal}
        onSuccess={this.onLinkProposalSuccess.bind(this)}
      />,
      true,
      false,
    )
  }

  onSelectGuarantor(guarantorsIds) {
    this.setState({ guarantorsIds })
  }

  onLinkProposalSuccess(response) {
    if (!response.isSuccess) {
      commons.notification({
        message: 'Ocorreu um erro ao criar link da proposta',
        status: 'danger',
      })

      return
    }
    this.props.notification({
      message: response.message,
    })
  }

  onCreateProposalSuccess(response) {
    const { activeCompany } = this.props

    this.props.closeModal()

    if (!response.isSuccess) {
      this.props.notification({
        message: 'Ocorreu um erro ao criar a proposta',
        status: 'danger',
      })

      ga.logEvent('proposal_error')

      return
    }

    this.props.notification({
      message: 'Proposta criada com sucesso',
    })
    const proposal = response.data.proposal || response.data

    const proposalType =
      proposal.type === 'TYPE_RENTAL'
        ? 'Locação'
        : proposal.type === 'TYPE_SALE'
        ? 'Venda'
        : 'Captação de imóveis'

    ga.logEvent('proposal_ended', {
      type: proposalType,
    })
    fb.logEvent('proposal_ended', {
      type: proposalType,
      proposalId: proposal.id,
      guarantee: proposal?.guarantee?.name || '',
      companyId: this.props.activeCompany.id,
      user: {
        email: this.props.activeUser.email,
        id: this.props.activeUser.id,
      },
    })

    if (proposal.type === 'TYPE_ACQUISITION') {
      this.props.history.push(
        `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/process/${response.data.id}`,
      )

      return
    }

    new Promise((resolve, reject) => {
      const token = localStorage.getItem('izee-token')
      const companyId = activeCompany.id
      const url = `${baseURL}company/${companyId}/guarantor/associate-proposal`
      const formData = {}
      const data = []
      const proposalId = proposal.id
      const { guarantorsIds } = this.state
      const headers = `Authorization: Bearer ${token}`

      if (!guarantorsIds) {
        return resolve(
          this.props.history.push(
            `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/proposal/${proposalId}`,
          ),
        )
      }

      guarantorsIds.map((id) => {
        data.push({
          proposal_id: proposalId,
          guarantor_id: id,
        })
      })

      formData['data'] = data

      request
        .post(url, formData, headers)
        .then(() => {
          resolve(
            this.props.history.push(
              `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/proposal/${proposalId}`,
            ),
          )
        })
        .catch(() => {
          reject(
            this.props.history.push(
              `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/proposal/${proposalId}`,
            ),
          )
        })
    })
  }
  gotoRecords() {
    this.props.history.push(
      `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/records/guests`,
    )
  }

  gotoRentalsAndSales() {
    this.props.history.push(
      `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}`,
    )
  }

  gotoPropertyCapture() {
    this.props.history.push(
      `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/property-capture`,
    )
  }

  gotoMarketplace() {
    this.props.history.push(
      `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/services`,
    )
  }

  gotoDashboard() {
    this.props.history.push(
      `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/dashboard`,
    )
  }
}

export default ReactTimeout(withModal(ScreenHeaderPrimary))
