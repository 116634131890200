import styled from 'styled-components'

export const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const InputContainer = styled.div`
  display: flex;
  background-color: ${props => (props.documentSaved ? '#d3dbe4' : '#f2f6fa')};
  max-width: ${props => (!props.isMobile ? '180px' : '100%')};
  justify-content: center;
  align-items: center;
`

export const Input = styled.input.attrs(props => ({
  type: 'file'
}))`
  height: 0;
  overflow: hidden;
  width: 0;

  + label {
    color: ${props => (props.documentSaved ? '#fff' : '#808996')};
    font-weight: 600;
    padding: 5px;
    cursor: pointer;
  }
`
export const Loading = styled.div`
  display: flex;
  padding: 5px;
  cursor: not-allowed;
  min-width: 180px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`
