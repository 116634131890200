import React from 'react'

import { request } from 'core/constants'

import SubmitButton from 'components/SubmitButton'
import Button from 'components/lib/Button'
import Grid from 'components/lib/Grid'

import formCreator from '../components/formCreator'
import { FormFieldSelect, FormFieldText } from 'components/lib/FormFields'
import { fieldProps } from '../components/formCreator'
import FullAddress from '../components/fieldsGroup/FullAddress'
import PropertyOwnerSelection from '../components/fieldsGroup/PropertyOwnerSelection'
import PropertyMeta from '../components/fieldsGroup/PropertyMeta'

import { goodObject, rawNumber } from 'utils/utils'
import { FieldContainer } from '../components/fieldsGroup/FullAddress/styles'

export class CreateProperty extends React.Component {
  constructor(props) {
    super(props)
    this.createRequest = this.createRequest.bind(this)
    this.state = {
      selectedType:
        props.defaultValue && props.defaultValue.type
          ? props.defaultValue.type.id
          : null,
      optionsTypesSelect: [],
      propertyOwners: [],
      defaultValue: {
        code: '',
        zip_code: '',
        address: '',
        state: '',
        city: '',
        neighborhood: '',
        additional_address: '',
        number: '',
      },
      fieldErrors: {},
    }
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest, true)

    const loadTypes = async () => {
      const response = await request.get('properties-type')
      const data = response.map((res) => {
        return {
          ...res,
          name: res.description,
        }
      }).sort((a, b) => a.name.localeCompare(b.name))

      this.setState({
        optionsTypesSelect: data,
      })
    }

    loadTypes()
  }

  handleChange(name, value) {
    const { defaultValue } = this.state
    const updatedFields = { ...defaultValue }

    if (name === 'fullAddress') {
      updatedFields['zip_code'] = value['cep'] || ''
      updatedFields['neighborhood'] = value['bairro'] || ''
      updatedFields['city'] = value['localidade'] || ''
      updatedFields['address'] = value['logradouro'] || ''
      updatedFields['state'] = value['uf'] || ''

      return this.setState({
        defaultValue: updatedFields,
      })
    }

    updatedFields[name] = value

    return this.setState({
      defaultValue: updatedFields,
    })
  }

  selectPropertyOwnerHandler(propertyOwners) {
    this.setState({ propertyOwners })
  }

  handleTypeChange(value, field) {
    if (value) {
      this.setState({
        selectedType: value,
      })
      this.props.handleFieldChanges('type_id', value, field)
    }
  }

  validatePropertyData() {
    const { code, zip_code, address, state, city, neighborhood, number } = this.state.defaultValue

    const fieldErrors = {}

    let isValid = true

    if (!code || code === '') {
      fieldErrors['code'] = ['Campo Código é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['code']
    }

    if (!zip_code || zip_code === '') {
      fieldErrors['zip_code'] = ['Campo CEP é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['zip_code']
    }

    if (!address || address === '') {
      fieldErrors['address'] = ['Campo Logradouro é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['address']
    }

    if (!state || state === '') {
      fieldErrors['state'] = ['Campo Estado é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['state']
    }

    if (!city || city === '') {
      fieldErrors['city'] = ['Campo Cidade é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['city']
    }

    if (!neighborhood || neighborhood === '') {
      fieldErrors['neighborhood'] = ['Campo Bairro é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['neighborhood']
    }

    if (!number || number === '') {
      fieldErrors['number'] = ['Campo Número é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['number']
    }

    this.setState({ fieldErrors })

    return isValid
  }

  render() {
    const defaultType =
      this.props.defaultValue && this.props.defaultValue.type
        ? this.props.defaultValue.type.id
        : null

    const type = this.state.selectedType || defaultType

    const { formIsWorking, formIsReady, isProcess, creatingProperty } = this.props

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col isNarrow>
            <FormFieldText
              isRequired
              {...fieldProps(this.state, 'code')}
              label='Código'
              style={{ width: 100 }}
              isDisabled={isProcess}
              onChange={ (e) => this.handleChange('code', e)}
              placeholder={isProcess ? 'Uso da imobiliária' : null}
              // required={true}
            />
          </Grid.Col>
          <Grid.Col>
            <FormFieldText
              {...fieldProps(this.props, 'name')}
              label='Apelido'
              noValidate
              isDisabled={isProcess}
              placeholder={isProcess ? 'Uso da imobiliária' : null}
            />
          </Grid.Col>
        </Grid>

        <FullAddress
          {...this.state}
          isRequired
          isMultiStep
          handleChange={this.handleChange.bind(this)}
          adicionalFields={
            <FieldContainer>
              <FormFieldSelect
                style={{ marginBottom: 0 }}
                {...fieldProps(this.props, 'type')}
                label='Tipo de imóvel'
                onChange={(value, field) => this.handleTypeChange(value, field)}
                value={type}
                valueKey='id'
                options={this.state.optionsTypesSelect}
              />
              <FormFieldText
                {...fieldProps(this.props, 'area')}
                label='Área privativa (m²)'
                mask={['onlyNumbers']}
              />
            </FieldContainer>
          }
        />

        <PropertyMeta {...this.props} noValidate />

        {!this.props.ownerId && !this.props.hidePropertyOwnerSelectionControl && (
          <PropertyOwnerSelection
            {...this.props}
            onSelect={this.selectPropertyOwnerHandler.bind(this)}
            showAddition
          />
        )}

        {!isProcess ? (
          <SubmitButton
            label='Salvar'
            isLoading={formIsWorking}
            isDisabled={formIsWorking}
          />
        ) : (
          <Button
            isLoading={creatingProperty}
            isDisabled={!formIsReady || formIsWorking}
            onClick={() =>
              this.props.handleProperty(this.state.defaultValue, this.props.fields)
            }
          >
            Adicionar
          </Button>
        )}
      </React.Fragment>
    )
  }

  createRequest(fields) {
    const { defaultValue } = this.state

    const isValid = this.validatePropertyData()

    if (!isValid) {
      this.props.cancelProgress()

      return false
    }

    fields['property_owner_ids'] = []

    if (this.props.ownerId) fields['property_owner_ids'].push(this.props.ownerId)

    if (this.state.propertyOwners.length)
      fields['property_owner_ids'].push(
        ...this.state.propertyOwners.map((item) => item.id),
      )

    let goodFields = goodObject(fields, {
      condo_fee: {
        path: 'condo_fee',
        format: (value) => rawNumber(value),
      },
      iptu: {
        path: 'iptu',
        format: (value) => rawNumber(value),
      },
      suggested_rental_value: {
        path: 'suggested_rental_value',
        format: (value) => rawNumber(value),
      },
      suggested_sale_value: {
        path: 'suggested_sale_value',
        format: (value) => rawNumber(value),
      },
    })

    // const { defaultValue } = this.state

    goodFields['code'] =  defaultValue.code
    goodFields['address'] = { ...defaultValue }

    setTimeout(() => {
      this.setState({
        defaultValue: {
          zip_code: '',
          address: '',
          state: '',
          city: '',
          neighborhood: '',
          additional_address: '',
          number: '',
        },
      })
    }, 1500)

    this.props.submit('{company}/property', goodFields)
  }
}

export default formCreator(CreateProperty, { stopPropagation: true })
