import React from "react";
import Button from "components/lib/Button";
import Icon from "components/lib/Icon";
import * as types from "../types";

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    const { percent, uploadId } = this.props;

    // console.log("upId", uploadId);

    if (percent == 0 || percent == 100) {
      return this.setState({ loading: false });
    }

    return this.setState({ loading: true });
  }

  onClick(row) {
    this.fileUpload.click();
    // this.props.uploading(row);
  }

  handleInput(e, row) {
    this.setState({ loading: true });
    return this.props.formFieldChange(e, row);
  }

  renderButtonSaved(percent, row) {
    const { loading } = this.state;

    if (loading && percent)
      return (
        <Button onClick={() => this.onClick(row)} custom="SubmitButton">
          {`Carregando...`}
          <Icon name="fas fa-times" />
        </Button>
      );

    return (
      <Button onClick={() => this.onClick(row)} custom="SavedButton">
        Documento salvo
        <Icon name="fas fa-times" />
      </Button>
    );
  }

  renderButtonSubmit(percent, row) {
    const { loading } = this.state;

    if (loading && percent)
      return (
        <Button onClick={() => this.onClick(row)} custom="SubmitButton">
          {`Carregando...`}
          <Icon name="fas fa-arrow-to-top" />
        </Button>
      );

    return (
      <Button onClick={() => this.onClick(row)} custom="SubmitButton">
        Enviar documento
        <Icon name="fas fa-arrow-to-top" />
      </Button>
    );
  }

  renderFileUpload() {
    const { row } = this.props;
    return (
      <input
        hidden
        type="file"
        name="Enviar documento"
        ref={ip => (this.fileUpload = ip)}
        onInput={e => this.handleInput(e, row)}
      />
    );
  }

  render() {
    const { row, percent } = this.props;

    switch (row.status) {
      case types.STATUS_REJECTED:
      case types.STATUS_WAITING:
        return (
          <React.Fragment>
            {this.renderFileUpload()}
            {this.renderButtonSubmit(percent, row)}
          </React.Fragment>
        );
      case types.STATUS_APPROVED:
      case types.STATUS_ANALYSIS:
        return (
          <React.Fragment>
            {this.renderFileUpload()}
            {this.renderButtonSaved(percent, row)}
          </React.Fragment>
        );
      default:
        return <Button>Ação não disponível. Verifique o status</Button>;
    }
  }
}
export default Component;
