import React from 'react';
import integrations from '../../';

import nivel0 from './icons/nivel0.png';
import nivel1 from './icons/nivel1.png';
import nivel2 from './icons/nivel2.png';
import nivel3 from './icons/nivel3.png';
import nivel4 from './icons/nivel4.png';
import nivel5 from './icons/nivel5.png';

export default class EditSolicitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      report: null,
      message: '',
    };
  }

  componentDidMount() {
    this.getSolicitation();
  }

  getSolicitation() {
    const { companyId, solicitationId } = this.props;

    this.setState({ isLoading: true, message: 'Buscando Resumo...' });

    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_DETAILS',
          companyId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (data.app.response.success) {
          this.setState({ report: data.app.response.data });

          this.setState({ isLoading: false, message: '' });
        } else {
          console.log('Fichacerta resumo do relatório falhou', success, data);

          this.props.notification({
            message: data.app.response.message || 'Falha ao receber detalhes',
            status: 'danger',
          });

          this.props.setModalState(false);
        }
      })
      .catch((error) => {
        console.log(
          'Error: summaryReport, get REPORT_DETAILS from ficha certa',
          error
        );

        this.props.notification({
          message:
            error.message || 'Não foi possível carregar os dados, tente novamente',
          status: 'danger',
        });

        this.props.setModalState(false);
      });
  }

  image(parecerKey) {
    switch (parecerKey) {
      case 'PARECER_NAO_APURADO':
        return nivel0;

      case 'PARECER_NIVEL_1':
        return nivel1;

      case 'PARECER_NIVEL_2':
        return nivel2;

      case 'PARECER_NIVEL_3':
        return nivel3;

      case 'PARECER_NIVEL_4':
        return nivel4;

      case 'PARECER_NIVEL_5':
        return nivel5;
    }
  }

  render() {
    const applicants = this.state.report?.pretendentes;

    return (
      <React.Fragment>
        {applicants ? (
          <>
            {applicants.map((applicant) => {
              return (
                <>
                  <div
                    style={{
                      padding: '8px',
                      border: '2px solid #F5F5F5',
                      backgroundColor: '#FbFbFb',
                      borderRadius: '8px',
                      fontSize: '14px',
                      marginBottom: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      {applicant.pessoa.razao_social && (
                        <div className="mb-1">
                          Razão Social: <strong>{applicant.pessoa.razao_social}</strong>
                        </div>
                      )}
                      {applicant.pessoa.nome && (
                        <div className="mb-1">
                          Nome: <strong>{applicant.pessoa.nome}</strong>
                        </div>
                      )}
                      <div className="mb-1">
                        Pretendente a:{' '}
                        <strong>{applicant.pessoa.tipo_pretendente}</strong>
                      </div>
                      {applicant.pessoa.cnpj && (
                        <div className="mb-1">
                          CNPJ: <strong>{applicant.pessoa.cnpj}</strong>
                        </div>
                      )}
                      {applicant.pessoa.cpf && (
                        <div className="mb-1">
                          CPF: <strong>{applicant.pessoa.cpf}</strong>
                        </div>
                      )}

                      <div>
                        Recomendações:
                        {applicant.laudo.parecer_sistemico.map((p) => {
                          return (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: p.recomendacao,
                              }}
                            ></p>
                          );
                        })}
                      </div>
                      <div>
                        Risco:
                        {applicant.laudo.parecer_sistemico.map((p) => {
                          return <p className="mb-0">{p.risco}</p>;
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignIitems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        minWidth: '85px',
                        marginLeft: '15px',
                      }}
                    >
                      <div>
                        {applicant.laudo.parecer_sistemico.map((p, i) => (
                          <div key={i}>
                            <img src={this.image(p.parecer_key)} />
                            <p className="mb-0">
                              <strong>{p.parecer}</strong>
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <div>
            <div style={{ height: '250px', marginTop: 100 }}>
              <div
                style={{ margin: '0 auto', padding: '45px 60px' }}
                className="empty-state-spinner"
              />
              <p className="text-center mt-2">{this.state.message}</p>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
